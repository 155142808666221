
.cml-logo {
 margin-bottom: 19px;
}
.sub-heading4 {
  color: #080808;
  font-size: 24px;
  opacity: 0.5;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 24px;
}

.sub-heading5 {
  padding-bottom: 30px;
  color: black;
  opacity: 0.5;
  font-weight: 400;
  font-size: 18px;
}

.header-text {
  text-align: center;
  margin-top: 20px;
}

.quiz-btn button {
  border: 1px solid white;
  background-color: #2bbbd3;
  padding: 10px 50px 10px 50px;
  border-radius: 4px;
  color: white;
}

