.custom-progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #e43193;
  transition: width 0.6s ease;
  border-radius: 10px;
}

.progress-main {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #f6d0e2;
  border-radius: 0.25rem;
  border: 1px solid #e43193;
  border-radius: 10px;
  margin-top: 6px;
}
