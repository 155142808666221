.desiredchild-cml-logo {
   margin-bottom: 19px;
  }
.desiredchild-sub-heading-1 {
  color: #080808;
  font-size: 24px;
  opacity: 0.5;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 24px;
}

.desiredchild-sub-heading-2 p {
  font-size: 28px;
  opacity: 0.5;
  font-weight: 400;
}

.desiredchild-header-text {
  text-align: center;
  margin-top: 20px;
}

.desiredchild-continue-btns button {
  border: 1px solid white;
  background-color: #2bbbd3;
  padding: 10px 50px 10px 50px;
  border-radius: 4px;
  color: white;
  margin-left: 20px;
}

.desiredchild-custom-radio-btn.active {
  background-color: #70dae4;
  color: black;
  border: none;
}

.desiredchild-custom-radio-btn {
  border-radius: 4px;
  width: 100%;
  padding-top: 15px;
  text-align: left;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
  background-color: #e4f6f8;
  color: #999999;
}

.desiredchild-details-textarea {
  border: 7px solid #dcf5f8;
  border-radius: 4px;
   width: 100%;
   margin-top: 10px;
 }
 
 .desiredchild-textarea {
   width: 100%;
   margin: auto;
 }
 .details-textarea {
  width: 100%;
  margin: auto;
}


.desiredchild-details-textarea::placeholder {
  padding-left: 5px;
  padding-top: 3px;
}

.desiredchild-continue-btns {
  text-align: center;
  margin-top: 20px;
}

.desiredchild-radiobtns {
  display: block;
}

.desiredchild-custom-radio-btn input[type='radio'] {
  padding: 0;
  opacity: 0;
  margin-left: -13px;
}

