.account-disabled-cml-logo {
  text-align: center;
 
}
.disabled-cml-logo{
  margin-bottom: 19px;
}

.account-disabled-title {
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.account-disabled-title b {
  color: black;
  opacity: 0.5;
}

.account-disabled-content {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}

.account-disabled-content p {
  color: #000000;
  opacity: 0.5;
}

.account-disabled-btns {
  margin-top: 40px;
  text-align: center;
}

.account-disabled-view-event-btn {
  background-color: rgba(43, 187, 211, 0.25);
}

.account-disabled-view-profile-btn {
  background-color: rgba(43, 187, 211, 0.25);
}

.account-disabled-view-event-btn button {
  padding: 15px 60px 15px 60px;
  background: none;
  font-size: 24px;
  font-weight: bold;
  color: #5c7274;
  border: none;
}

.account-disabled-view-profile-btn button {
  padding: 15px 53px 15px 59px;
  background: none;
  font-size: 24px;
  font-weight: bold;
  color: #5c7274;
  border: none;
}

.account-disabled-view-profile-btn button:focus {
  outline: none;
}

.account-disabled-view-event-btn button:focus {
  outline: none;
}

@media only screen and (max-width: 600px) {
  .account-disabled-view-profile-btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .account-disabled-view-event-btn button {
    padding: 10px 20px 10px 20px;
    background: none;
    font-size: 19px;
    font-weight: bold;
    color: #5c7274;
  }

  .account-disabled-view-profile-btn button {
    padding: 10px 16px 10px 16px;
    background: none;
    font-size: 19px;
    font-weight: bold;
    color: #5c7274;
  }
}

@media only screen and (min-width: 992px) {
  .account-disabled-view-event-btn button {
    padding: 12px 35px 11px 35px;
    background: none;
    font-size: 24px;
    font-weight: bold;
    color: #5c7274;
  }

  .account-disabled-view-profile-btn button {
    padding: 15px 30px 15px 30px;
    background: none;
    font-weight: bold;
    color: #5c7274;
  }
}

@media only screen and (min-width: 1200px) {
  .account-disabled-view-event-btn button {
    padding: 15px 55px 15px 55px;
    background: none;
    font-size: 24px;
    font-weight: bold;
    color: #5c7274;
  }

  .account-disabled-view-profile-btn button {
    padding: 15px 55px 15px 55px;
    background: none;
    font-size: 24px;
    font-weight: bold;
    color: #5c7274;
  }
}
