.health-cml-logo {
 margin-bottom: 19px;
}
.health-sub-heading-1 {
  color: #080808;
  font-size: 24px;
  opacity: 0.5;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 24px;
}

.health-sub-heading-2 p {
  font-size: 28px;
  opacity: 0.5;
  font-weight: 400;
}

.health-header-text {
  text-align: center;
  margin-top: 20px;
}

.health-screen-btns button {
  border: 1px solid white;
  background-color: #2bbbd3;
  padding: 10px 50px 10px 50px;
  border-radius: 4px;
  color: white;
}

.health-screen-btns {
  text-align: center;
}
.health-continue-btn{
  margin-left: 10px;
}

.health-travel-textarea {
  margin-top: 12px;
}

.health-details-textarea {
  width: 100%;
  margin-top: 30px;
  border: 7px solid #dcf5f8;
  border-radius: 4px;
}

.health-travel-textarea {
  width: 100%;
  margin: auto;
}
.health-textarea {
  width: 100%;
  margin: auto;
}

.health-details-textarea::placeholder {
  padding-left: 5px;
  padding-top: 3px;
}

.health-progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #f6d0e2;
  border-radius: 0.25rem;
  border: 1px solid #e43193;
  border-radius: 10px;
  margin-top: 6px;
}

.health-progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #e43193;
  transition: width 0.6s ease;
  border-radius: 10px;
  width: 83.3%;
}

.health-lock-icon {
  font-size: 32px;
}


