
  .children-end-cml-logo {
    margin-bottom: 19px;
  }
  
  .children-end-heading-1 {
    color: #080808;
    font-size: 24px;
    opacity: 0.5;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 24px;
  }
  
  .children-end-sub-heading-1 p {
    font-size: 28px;
    opacity: 0.5;
    font-weight: 400;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
  }
  
  .children-end-header-text {
    text-align: center;
    margin-top: 20px;
  }
  
  .children-end-lets-go-btns button {
    border: 1px solid white;
    background-color: #2bbbd3;
    padding: 10px 50px 10px 50px;
    border-radius: 4px;
    color: white;
    margin: 5px;
  }
  .children-end-lets-go-btns{
    text-align: center;
    margin-top: 70px;
  }
  
  .children-end-progress {
    border: 1px solid #e43193;
    border-radius: 10px;
    margin-top: 50px;
    width: 70%;
    margin: auto;
  }
  
  .children-end-progress-bar {
    background-color: #e43193;
    border-radius: 10px;
    width: 80%;
  }
  
  
  .children-progress-text1 {
    position: absolute;
    font-size: 18px;
    color: black;
    opacity: 0.5;
    bottom: 155px;
    left: 21%;
    font-weight: 400;
  }
  
  .children-progress-text2 {
    position: absolute;
    font-size: 18px;
    color: black;
    opacity: 0.5;
    bottom: 115px;
    left: 33%;
    font-weight: 400;
  }
  
  .children-progress-text3 {
    position: absolute;
    font-size: 18px;
    color: black;
    opacity: 0.5;
    bottom: 155px;
    left: 47%;
    font-weight: 400;
  }
  
  .children-progress-text4 {
    position: absolute;
    font-size: 18px;
    color: black;
    opacity: 0.5;
    bottom: 115px;
    left: 60%;
    font-weight: 400;
  }
  
  .children-progress-text5 {
    position: absolute;
    font-size: 20px;
    color: black;
    opacity: 0.5;
    bottom: 155px;
    left: 72%;
    font-weight: 400;
  }
  
  
  @media only screen and (max-width: 414px) and (min-width: 375px) {
  
    .children-end-progress {
      border: 1px solid #e43193;
      border-radius: 10px;
      margin-top: 50px;
      width: 70%;
      margin: auto;
    }
  
    .children-end-progress-bar {
      background-color: #e43193;
      border-radius: 10px;
      width: 80%;
    }
  
    .children-progress-text1 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155px;
      left: 22%;
      font-weight: 400;
    }
  
    .children-progress-text2 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 115px;
      left: 33%;
      font-weight: 400;
    }
  
    .children-progress-text3 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155px;
      left: 45%;
      font-weight: 400;
    }
  
    .children-progress-text4 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 115;
      left: 55%;
      font-weight: 400;
    }
  
    .children-progress-text5 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155px;
      left: 64%;
      font-weight: 400;
    }
  
  }
  
  @media only screen and (min-width: 414px) and (max-width: 768px) {
  
    .children-end-progress {
      border: 1px solid #e43193;
      border-radius: 10px;
      margin-top: 50px;
      width: 70%;
      margin: auto;
    }
  
    .children-end-progress-bar {
      background-color: #e43193;
      border-radius: 10px;
      width: 80%;
    }
  
    .children-progress-text1 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155px;
      left: 22%;
      font-weight: 400;
    }
  
    .children-progress-text2 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 115;
      left: 32%;
      font-weight: 400;
    }
  
    .children-progress-text3 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155px;
      left: 45%;
      font-weight: 400;
    }
  
    .children-progress-text4 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 115;
      left: 56%;
      font-weight: 400;
    }
  
    .children-progress-text5 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155px;
      left: 66%;
      font-weight: 400;
    }
  
    .children-end-lets-go-btn button {
      border: 1px solid white;
      background-color: #2bbbd3;
      padding: 6px 30px 6px 30px;
      border-radius: 4px;
      color: white;
      position: absolute;
      top: 108%;
      left: 35%;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 992px) {

    .children-end-progress {
      border: 1px solid #e43193;
      border-radius: 10px;
      margin-top: 50px;
      width: 70%;
      margin: auto;
    }
  
    .children-end-progress-bar {
      background-color: #e43193;
      border-radius: 10px;
      width: 80%;
    }
  
    .children-progress-text1 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155px;
      left: 22%;
      font-weight: 400;
    }
  
    .children-progress-text2 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 115px;
      left: 32%;
      font-weight: 400;
    }
  
    .children-progress-text3 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155;
      left: 45%;
      font-weight: 400;
    }
  
    .children-progress-text4 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 115px;
      left: 57%;
      font-weight: 400;
    }
  
    .children-progress-text5 {
      position: absolute;
      font-size: 12px;
      color: black;
      opacity: 0.5;
      bottom: 155px;
      left: 68%;
      font-weight: 400;
    }
  
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1024px) {

    .children-end-progress {
      border: 1px solid #e43193;
      border-radius: 10px;
      margin-top: 50px;
      width: 70%;
      margin: auto;
    }
  
    .children-end-progress-bar {
      background-color: #e43193;
      border-radius: 10px;
      width: 80%;
    }
  
    .children-progress-text1 {
      position: absolute;
      font-size: 18px;
      color: black;
      opacity: 0.5;
      bottom: 158px;
      left: 20%;
      font-weight: 400;
    }
  
    .children-progress-text2 {
      position: absolute;
      font-size: 18px;
      color: black;
      opacity: 0.5;
      bottom: 110px;
      left: 31%;
      font-weight: 400;
    }
  
    .children-progress-text3 {
      position: absolute;
      font-size: 18px;
      color: black;
      opacity: 0.5;
      bottom: 158px;
      left: 45%;
      font-weight: 400;
    }
  
    .children-progress-text4 {
      position: absolute;
      font-size: 18px;
      color: black;
      opacity: 0.5;
      bottom: 110px;
      left: 58%;
      font-weight: 400;
    }
  
    .children-progress-text5 {
      position: absolute;
      font-size: 20px;
      color: black;
      opacity: 0.5;
      bottom: 158px;
      left: 70%;
      font-weight: 400;
    }
  
  }
  
  @media only screen and (min-width: 1025px) {

    .children-end-progress {
      border: 1px solid #e43193;
      border-radius: 10px;
      margin-top: 50px;
      width: 70%;
      margin: auto;
    }
  
    .children-end-progress-bar {
      background-color: #e43193;
      border-radius: 10px;
      width: 80%;
    }
  

  }